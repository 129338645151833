<template>
  <div class="home" style="height: 100%; width: 100%">
    <v-container style="height: 70%; width: 100%">
      <v-row
        style="
          width: 100%;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        "
      >
        <v-col cols="2"></v-col>
        <!-- TODO: issue #125 -->
        <!-- eslint-disable vue/no-v-html -->
        <v-col cols="8" style="text-align: center; font-size: x-large"
          ><p
            v-html="
              $t('welcome.welcomeMsg', {
                customerName: $store.state.customerName,
              })
            "
          />
          <p
            v-if="$store.direction == 'IN'"
            v-html="
              $t('welcome.deposit', {
                vehicleBrand: $store.state.vehicleBrand,
                vehicleModel: $store.state.vehicleModel,
                vehicleImmat: $store.state.vehicleImmat,
              })
            "
          />
          <p
            v-else
            v-html="
              $t('welcome.pick', {
                vehicleBrand: $store.state.vehicleBrand,
                vehicleModel: $store.state.vehicleModel,
                vehicleImmat: $store.state.vehicleImmat,
              })
            "
          />
          <p>{{ $t("welcome.welcomeEnd", { next: $t("main.next") }) }}</p>
        </v-col>
        <!-- eslint-enable -->
        <v-col cols="2" />
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
  mounted() {
    var callback_url, appt_id, has_motors, loading;
    if (this.$route.query.appt === "") {
      callback_url = this.$route.query.backUrl;
      appt_id = this.$route.query.id;
      has_motors = false;
      loading = "loadApptExt";
    } else {
      callback_url = this.$route.query.callback;
      appt_id = this.$route.query.appt;
      has_motors = true;
      loading = "loadAppt";
    }

    // timeout is centrally managed on visionor device so there should be no need for it
    // on visionor... only useful on locktec
    this.$store.dispatch({
      type: "loadCallback",
      callback: callback_url,
    });

    this.$store.dispatch({
      type: loading,
      id: appt_id,
      has_motors: has_motors,
    });

    if (this.$route.query.lang) {
      this.$store.commit({ type: "lang", lang: this.$route.query.lang });
      this.$i18n.locale = this.$route.query.lang;
    } else {
      this.$store.commit({ type: "lang", lang: "fr" });
      this.$i18n.locale = "fr";
    }

    if (this.$route.query.style) {
      if (this.$route.query.style == "cav") {
        this.$vuetify.theme.themes.light.success = "#a69578";
        this.$vuetify.theme.themes.dark.success = "#a69578";
      } else {
        this.$vuetify.theme.themes.light.success = "#0000ff";
        this.$vuetify.theme.themes.dark.success = "#0000ff";
      }
    }
  },
};
</script>
