var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home",staticStyle:{"height":"100%","width":"100%"}},[_c('v-container',{staticStyle:{"height":"70%","width":"100%"}},[_c('v-row',{staticStyle:{"width":"100%","position":"relative","top":"50%","transform":"translateY(-50%)"}},[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{staticStyle:{"text-align":"center","font-size":"x-large"},attrs:{"cols":"8"}},[_c('p',{domProps:{"innerHTML":_vm._s(
            _vm.$t('welcome.welcomeMsg', {
              customerName: _vm.$store.state.customerName,
            })
          )}}),(_vm.$store.direction == 'IN')?_c('p',{domProps:{"innerHTML":_vm._s(
            _vm.$t('welcome.deposit', {
              vehicleBrand: _vm.$store.state.vehicleBrand,
              vehicleModel: _vm.$store.state.vehicleModel,
              vehicleImmat: _vm.$store.state.vehicleImmat,
            })
          )}}):_c('p',{domProps:{"innerHTML":_vm._s(
            _vm.$t('welcome.pick', {
              vehicleBrand: _vm.$store.state.vehicleBrand,
              vehicleModel: _vm.$store.state.vehicleModel,
              vehicleImmat: _vm.$store.state.vehicleImmat,
            })
          )}}),_c('p',[_vm._v(_vm._s(_vm.$t("welcome.welcomeEnd", { next: _vm.$t("main.next") })))])]),_c('v-col',{attrs:{"cols":"2"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }